import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Von der Rechnung beim Online-Shoppen bis zum amtlichen Bescheid: PDF-Dokumente sind heute allgegenwärtig.
Zahlreiche Unternehmen und Organisation nutzen das Dateiformat, um Informationen darzustellen und auszutauschen.
Doch sind PDFs auch barrierefrei? Die kurze Antwort: Nicht automatisch!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHoZWTi44n/xAAcEAACAQUBAAAAAAAAAAAAAAABAgMAEBESITH/2gAIAQEAAQUCkcq3dwWqTxTi3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAQABBQEAAAAAAAAAAAAAAAERAAIQITFB/9oACAEBAAY/AgDtRa7K8xaR1jH/xAAaEAADAQADAAAAAAAAAAAAAAAAARExIUHR/9oACAEBAAE/IYfFpwvLVpcH14EqEXYqMSR//9oADAMBAAIAAwAAABBrD//EABcRAQADAAAAAAAAAAAAAAAAAAARIVH/2gAIAQMBAT8QrEv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QjH//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFhgdHw/9oACAEBAAE/EHNYAWM819uDrICm0e+5Y1GcLS+4RtCNwzQqHMAXzqGIug5n/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Dokument mit Textblöcken und Grafiken.",
          "title": "Ein Dokument mit Textblöcken und Grafiken.",
          "src": "/static/51b74b5ce5bbce433e7a8554cd2cb535/e5166/pexels-antoni-shkraba-document.jpg",
          "srcSet": ["/static/51b74b5ce5bbce433e7a8554cd2cb535/f93b5/pexels-antoni-shkraba-document.jpg 300w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/b4294/pexels-antoni-shkraba-document.jpg 600w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/e5166/pexels-antoni-shkraba-document.jpg 1200w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/b17f8/pexels-antoni-shkraba-document.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © ANTONI SHKRABA production / pexels.com`}</em></p>
    <p>{`Der Standard `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/PDF/UA"
      }}>{`PDF/UA`}</a>{` wurde 2012 veröffentlicht. Dieser
definiert, wie ein PDF-Dokument die Anforderungen an Barrierefreiheit erfüllen kann. Leider werden auch heute
noch zahlreiche Dokumente erstellt, welche diesen Standard nicht einhalten.`}</p>
    <p>{`Damit euch das nicht passiert, solltet ihr eure PDFs auf Barrierefreiheit prüfen. Diese Prüfung beginnt am
besten immer mit dem `}<BoldText mdxType="BoldText">{`PDF Accessibility Checker`}</BoldText>{`, kurz PAC. Dieses kostenlose Standardtool ist
vor kurzem in der Version 2024 erschienen: `}<a parentName="p" {...{
        "href": "https://pac.pdf-accessibility.org/de"
      }}>{`https://pac.pdf-accessibility.org/de`}</a></p>
    <p>{`Ich nutze das Tool schon seit einigen Jahren und weiß seine Stärken zu schätzen. Allerdings ersetzt das
automatisierte Tool keine manuelle Prüfung, etwa mit Screenreader und Tastatur. Daher gebe ich euch einen kurzen
Überblick zu den Stärken und Schwächen des PDF Accessibility Checkers.`}</p>
    <h2>{`Stärken des Prüftools`}</h2>
    <p>{`Der PAC ist ein Tool zur automatisierten Prüfung von PDF-Dokumenten auf Barrierefreiheit, gemäß dem Standard PDF/UA
(DIN/ISO 14289–1). Darüber hinaus werden relevante Punkte der `}<ItalicText mdxType="ItalicText">{`Web Content Accessibility Guidelines (WCAG)`}</ItalicText>{`
sowie zusätzliche Qualitätsmerkmale geprüft.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAABsklEQVQ4y41T2W7cMAz0//9HP6QPeelLURRI026Rtpt1LOuWfKx8yFOQGxmbBFtUwICSTA05JF09fDvg4f6A09MzzuczUko7pmliOwwDjNZo25YhhICUElprGGNeofr54xe+fPqMw/dHlLVtGzIhZ6w5Y0oJ3jnEGJDOl0DzPGNZFlhroZSCc+5C2AUPLIkdQoh8SR+7ruPMCOM48jmlEc4Z3q/rysFJwTiMHIQUViYM+PDxHrVooaTE8XhE0zRMWsjI9l3PGcfomYRUbEXFuvKebBW6HndfH2GdRwyBo1M0IiogQrpflhnOWYQQmKiQkDo6UwkqYxS6YKFki9Of32gawbL7vt8ldzHCaY01L/DessS91i8Z7oTUrVNdc+dK18h671k22bLPeUUIDt75vYbvCI2RXGiSeb22LbMUciZQw+ZlgtYSSundvxASLoRWQYhnllhkFJADPSB4R1Ijl2BK0z9qaCWapoZznh2uScmhRKc5XObpnc9bVD54Tp8ev13Xo8GdvUHyijDGWJ7fdLrMX8T/rEpqi7o10DbA07i8jMo+MiHAiAatEPybUbfJ3sJf0afuqpmL9xMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ergebnis der Barrierefreiheits-Prüfung eines PDF-Dokuments mit dem PAC 2024.",
          "title": "Ergebnis der Barrierefreiheits-Prüfung eines PDF-Dokuments mit dem PAC 2024.",
          "src": "/static/a46a44a187a3d3a001e3ec982f15bb8e/c1b63/screenshot-pac-2024-details.png",
          "srcSet": ["/static/a46a44a187a3d3a001e3ec982f15bb8e/5a46d/screenshot-pac-2024-details.png 300w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/0a47e/screenshot-pac-2024-details.png 600w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/c1b63/screenshot-pac-2024-details.png 1200w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/71c1d/screenshot-pac-2024-details.png 1536w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Dabei kann der PAC u.a. folgende Barrieren automatisch erkennen:`}</p>
    <ul>
      <li parentName="ul">{`Für das PDF-Dokument ist kein Titel gesetzt.`}</li>
      <li parentName="ul">{`Die Sprache des Dokuments ist nicht definiert.`}</li>
      <li parentName="ul">{`Die Inhalte des PDF-Dokuments sind nicht getaggt.`}</li>
      <li parentName="ul">{`Ein Bild verfügt über keinen Alternativtext.`}</li>
    </ul>
    <p>{`Weiters vermittelt das Tool im WCAG-Reiter auch transparent, welche Richtlinien es überprüft und welche nicht. Richtlinien
wie z.B. `}<ItalicText mdxType="ItalicText">{`„2.1 Per Tastatur zugänglich“`}</ItalicText>{` und `}<ItalicText mdxType="ItalicText">{`„3.2 Vorhersehbar“`}</ItalicText>{` können nur
manuell geprüft werden.`}</p>
    <h2>{`Schwächen des Prüftools`}</h2>
    <p>{`Der PDF Accessibility Checker kann den Code eines PDF-Dokuments nach gewissen Regeln prüfen. Aber es kann den Inhalt des
Dokuments nicht inhaltlich verstehen und interpretieren. Das können automatisierte Prüftools generell nicht.`}</p>
    <p>{`Um die Schwächen des Tools aufzuzeigen, habe ich ein Experiment durchgeführt: Wie viele Barrieren kann ich in ein PDF-Dokument
einbauen, ohne dass der PAC Alarm schlägt? Mein `}<a parentName="p" {...{
        "href": "/pdf/pdf_accessibility_test_de.pdf"
      }}>{`Test-Dokument`}</a>{` besteht aus mehreren
Überschriften, Fließtext und einem Bild. Ihr könnt das Dokument herunterladen und selbst überprüfen.`}</p>
    <p>{`Sehen wir uns nun die einzelnen Barrieren im Detail an.`}</p>
    <h3>{`Kein aussagekräftiger Titel`}</h3>
    <p>{`Mein Test-Dokument hat den Titel `}<ItalicText mdxType="ItalicText">{`„Ein schöner Titel“`}</ItalicText>{`. PAC gibt sich damit zufrieden. Wenn jedoch
Screenreader-Nutzer:innen diesen Titel zu hören bekommen, werden sie nicht viel damit anfangen können.`}</p>
    <p>{`Der Titel einer Webseite oder eines Dokuments ist die erste Information, auf die Nutzer:innen stoßen. Der Titel sollte
den Inhalt beschreiben und den Nutzer:innen Orientierung bieten. Das hierbei relevante
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/page-titled.html"
      }}>{`WCAG-Erfolgskriterium 2.4.2`}</a>{` besagt:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Webseiten haben einen Titel, der Thema oder Zweck beschreibt.`}</p>
    </blockquote>
    <p>{`Daher muss bei einer manuellen Prüfung ein Mensch einschätzen, ob der gesetzte Titel des PDF-Dokuments aussagekräftig
genug ist.`}</p>
    <h3>{`Falsch getaggte Überschriften`}</h3>
    <p>{`Wie PAC korrekt feststellt, ist der gesamte Inhalt meines Test-Dokuments getaggt. Das bedeutet: Für jedes Element im PDF ist
definiert, welche Art von Inhalt es darstellt. Diese Information ist u.a. für Screenreader-Nutzer:innen essenziell, um den
Inhalt korrekt erfassen und schnell im Dokument navigieren zu können.`}</p>
    <p>{`Allerdings habe ich PAC reingelegt: Alle Überschriften, wie z.B. `}<ItalicText mdxType="ItalicText">{`„Das ist eine wunderschöne Überschrift“`}</ItalicText>{`,
sind mit dem `}<InlineCode mdxType="InlineCode">{`<`}{`P`}{`>`}</InlineCode>{`-Tag als Fließtext ausgezeichnet. Während sehende Menschen die Überschriften
visuell als solche erfassen können, ist diese Information semantisch nicht erfasst. Das
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/info-and-relationships.html"
      }}>{`WCAG-Erfolgskriterium 1.3.1`}</a>{` fordert:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Informationen, Struktur und Beziehungen, die über die Darstellung vermittelt werden, können durch Software bestimmt werden
oder stehen in Textform zur Verfügung.`}</p>
    </blockquote>
    <p>{`Automatisierte Tools wie der PAC können nicht einschätzen, was als Überschrift getaggt sein sollte und was nicht. Daher sollte
sich ein Mensch das PDF-Dokument von einem Screenreader vorlesen lassen. Zusätzlich bietet auch PAC selbst die nützliche
Screenreader-Vorschau-Funktion, welche die Tagging-Struktur visualisiert.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAAC30lEQVQozz2SW09TaRSG96Uzib/Ai7n0B5AQAokJRhL0zngzEmeYxKhR44i0SkRjTEacMHgWVMACpS2HkjJVKqUnWnrkUERLC3RbsLvlqGQqHRAIh8fs7eFLnqy1Lr7vXWt9r/DS5SIwaiUmjhKZHCYaDxOfGSORfPuD+MxrFpanqbl7h5ycHIqLiyksLKSgoIC8vDxyc3OVPD8/H+HYnxe4ry3F5m/F7HrGi/76H1g8DQpy3uNuoN/nZmhwCKfTidvtVqLH48HlchEKhYhEIggnLqt50n4GZ6gdi0dDr7cFq+8rvd5mbAEt7mEDA8M6xBmRjfVNkkmJdHoWSUohSWmF2bl5dndBOK5SUd95EV+4C7t8eciAL9yO9xv+0Q4G3xpxBluJTIyz9v8aCwuLLC4usbT0gUwmQ3blE6srn9jc2EAoUauoazuLI9BGj1tDn1+rIHcoC9gDrUqXFreGuBhl5AM8HdvGEN3GGNumZxr0U/DPyGd80gpCiUrFQ905Xg3oMTsblQccQR3OoA7XoB5XSK9EWSyRiNEx/pmS9jmuWpe53vcfNY5lqurq+f38Mcy9Wnnkchq7TuMLd2LzN2H3N2Hza3jlbVJ22OeXacEWaCaVEqnqibLvYhf5VXYO3LZTeNvCUfUtDhUdxNBmkD9Fxa2Gk1gG9Lz0PMfYr0Nn1dI/qMMRbFV2GhzrVGpJErmi9SEcrmLvicfs+a2Wn081sufIVYSffuFm9SOEP9Rl/NX8kGrvR556U/xt8nCv4zmJqAlxvBsxYiIR7SYc0iO+m8Q/nqTa4KS228t90wAPzD5qjQ7uPtYwNDKKcLqijF8rL7H/RpADNQHySitRl5WSkax8nLYoZJK9pGImJqZi7GztsJrNspZdha1N2N0Cdvh+hJMVKs5VllJUUcfhm3UcOn+DymvlpKdekJz4VyE9aSb+xkgy9Z6V7CrTMxLvZf+l50jPziselH25vr7OFzIUou7TDlb4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Vergleich der Screenreader-Vorschau mit der visuellen Darstellung des Test-Dokuments.",
          "title": "Vergleich der Screenreader-Vorschau mit der visuellen Darstellung des Test-Dokuments.",
          "src": "/static/03da873019463d92a7d723a399026111/c1b63/screenshot-pac-2024-test-pdf.png",
          "srcSet": ["/static/03da873019463d92a7d723a399026111/5a46d/screenshot-pac-2024-test-pdf.png 300w", "/static/03da873019463d92a7d723a399026111/0a47e/screenshot-pac-2024-test-pdf.png 600w", "/static/03da873019463d92a7d723a399026111/c1b63/screenshot-pac-2024-test-pdf.png 1200w", "/static/03da873019463d92a7d723a399026111/d61c2/screenshot-pac-2024-test-pdf.png 1800w", "/static/03da873019463d92a7d723a399026111/8d7fa/screenshot-pac-2024-test-pdf.png 1813w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`Keine logische Lesereihenfolge`}</h3>
    <p>{`Seht euch den Screenshot oben nochmal genau an. Na, fällt euch was auf? Genau, das Bild befindet sich visuell an einer anderen
Stelle als in der Screenreader-Vorschau.`}</p>
    <p>{`In einem getaggten PDF wird die Lesereihenfolge separat gespeichert. Besonders bei Dokumenten mit komplexen Layouts kann damit
eine logische Lesereihenfolge definiert werden. Wenn etwa blinde Nutzer:innen mit Screenreader durch das Dokument
navigieren, folgt der virtuelle Cursor des Screenreaders dieser Reihenfolge.`}</p>
    <p>{`Daher definiert das `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/meaningful-sequence.html"
      }}>{`WCAG-Erfolgskriterium 1.3.2`}</a>{`
folgende Bedingung für Barrierefreiheit:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wenn die Reihenfolge, in der Inhalte präsentiert werden, sich auf deren Bedeutung auswirkt, kann die korrekte Leseabfolge
durch Software bestimmt werden.`}</p>
    </blockquote>
    <p>{`Bei einer manuellen Prüfung sollte daher ein Mensch mit aktiviertem Screenreader sequenziell durch das Dokument navigieren
und dabei überlegen, ob die Abfolge der Inhalte Sinn macht. Die visuelle Anordnung dient hier als Richtschnur. Es kann
jedoch auch eine davon abweichende Lesereihenfolge sinnvoll sein.`}</p>
    <h3>{`Alternativtext beschreibt das Bild nicht`}</h3>
    <p>{`Der PDF Accessibility Checker prüft Bilder nur darauf, ob diese korrekt getaggt sind und ein Alternativtext definiert ist.
Das `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/non-text-content.html"
      }}>{`WCAG-Erfolgskriterium 1.1.1`}</a>{` verlangt aber mehr:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Alle Nicht-Text-Inhalte, die dem Benutzer präsentiert werden, haben eine Textalternative, die einem äquivalenten Zweck
dient, mit Ausnahme der unten aufgelisteten Situationen. `}{`[`}{`...`}{`]`}</p>
    </blockquote>
    <p>{`Das Bild in meinem Test-Dokument zeigt das blaue, offene Meer vor der Küste Mallorcas. Der Alternativtext des Bildes lautet
hingegen `}<ItalicText mdxType="ItalicText">{`„Eine staubtrockene Wüste“`}</ItalicText>{`. Ein automatisiertes Tool kann nicht erkennen, dass das Quatsch ist.`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Der `}<BoldText mdxType="BoldText">{`PDF Accessibility Checker`}</BoldText>{` ist ein großartiges Tool, um ein PDF-Dokument auf grobe Mängel zu prüfen.
Die manuelle Prüfung ersetzt es aber nicht. Eine umfassende Prüfung der Barrierefreiheit sollte sowohl automatisierte als
auch manuelle Prüfschritte umfassen.`}</p>
    <p>{`PS: Testet auch die `}<a parentName="p" {...{
        "href": "/pdf/pdf_accessibility_test_de_korr.pdf"
      }}>{`korrigierte Fassung meines Test-Dokuments`}</a>{` mit dem Screenreader,
um die verbesserte Nutzererfahrung selbst zu erleben.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      